import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import moment from "moment";
import {
  deleteRequest,
  getRequest,
  patchRequest,
} from "../../../../../utils/axiosRequests";
import { Link } from "react-router-dom";
import { sonodTypes } from "../../../../../utils/helpers";
import { useSelector } from "react-redux";
import Pagination from "../../../../Global/pagination";
import SearchCertificate from "../../../../Global/SearchCertificate";
import { toast } from "react-toastify";
import CommonModal from "../../../../Ui-Kits/Modal/Common/CommonModal";
import StaticForm from "../../../../Ui-Kits/Modal/StaticBackdropModal/StaticForm";
import { Btn, H3 } from "../../../../../AbstractElements";

const HtmlSourcedData = () => {
  const [searchKey, setSearchKey] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const userInfo = useSelector((state: AppState) => state?.auth?.data);
  const [certificates, setCertificates] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const modalTwoToggle = () => setModalOpen(!modalOpen);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [message, setMessage] = useState("");

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  // const onFileUpload = async () => {
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);

  //   try {
  //     patchRequest(`certificates/user-image-upload/${userId}`, formData)
  //       .then((res) => {
  //         console.log(res, "res");
  //         setImageUrl(res.path);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   } catch (error) {
  //     setMessage("Error uploading file");
  //   }
  // };

  // useEffect(() => {
  //   const queryUrl = `certificates?page=${page}`;
  //   getCertificates(queryUrl);
  // }, [page]);

  useEffect(() => {
    let queryUrl = `certificates?page=${page}&type=${certificateType}`;
    if (
      (userInfo?.userType === "user" || userInfo?.userType === "chairman") &&
      searchKey.length > 0
    ) {
      queryUrl = `certificates?page=${page}&unionId=${userInfo?.unionId?.id}&type=${certificateType}&searchKey=${searchKey}`;
    } else if (
      (userInfo?.userType === "user" || userInfo?.userType === "chairman") &&
      searchKey.length === 0
    ) {
      queryUrl = `certificates?page=${page}&unionId=${userInfo?.unionId?.id}&type=${certificateType}`;
    } else if (userInfo?.userType === "admin" && searchKey.length > 0) {
      queryUrl = `certificates?page=${page}&type=${certificateType}&searchKey=${searchKey}`;
    }
    getCertificates(queryUrl);
  }, [searchKey, page, perPage, certificateType]);

  const getCertificates = (url: any) => {
    getRequest(url)
      .then((res: any) => {
        setIsLoading(true);
        setCertificates(res.data);
        setTotalPages(Math.ceil(res.total / perPage));
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const approvedCertificate = (certificate: any) => {
    var result = window.confirm("Are you sure want to approve?");
    if (result) {
      patchRequest(`certificates/${certificate._id}`, { status: true })
        .then((data: any) => {
          let index = certificates.findIndex(
            (item: any) => item._id === certificate._id
          );
          certificates[index]["status"] = true;
          setCertificates([...certificates]);
          // toast.success("Successfully approved", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const getSonodName = (sonodId: any) => {
    const result = sonodTypes().find((sonod: any) => sonod.id == sonodId);
    return result ? result.name : "";
  };

  const deleteCertificate = (id: any) => {
    var result = window.confirm("Are you sure want to delete?");
    if (result) {
      deleteRequest(`certificates/${id}`)
        .then((data: any) => {
          const result = certificates.filter((item: any) => item._id !== id);
          setCertificates([...result]);
          toast.success("Successfully deleted", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Col sm="12">
      <Card>
        <CardBody className="custom-card-body">
          <div className="custom-flex">
            <h6 className="card-title mb-0">সনদ লিস্ট</h6>
            <SearchCertificate
              setCertificateType={setCertificateType}
              setSearchKey={setSearchKey}
            />
          </div>
          <div className="table-responsive">
            <table className="list-table table-hover my-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>সনদ নং</th>
                  <th>সনদ টাইপ</th>
                  <th>আবেদনকারীর নাম</th>
                  <th>ইস্যু তারিখ</th>
                  <th>স্ট্যাটাস</th>
                  <th
                    className="d-none d-md-table-cell"
                    //style={{ width: "200px" }}
                  >
                    #
                  </th>
                </tr>
              </thead>
              <tbody>
                {certificates?.length > 0 &&
                  certificates.map((certificate: any, index: any) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{certificate?.certificateNo}</td>
                      <td>{getSonodName(certificate?.type)}</td>
                      <td className="td-text-left">
                        {certificate?.details?.applicantName}
                      </td>
                      <td>
                        {moment(certificate?.issueDate).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <button
                          className={`btn btn-xs ${
                            certificate.status ? "btn-success" : "btn-warning"
                          }`}
                          {...(!certificate?.status &&
                          userInfo?.userType !== "user"
                            ? {
                                onClick: () => {
                                  approvedCertificate(certificate);
                                },
                              }
                            : {})}
                        >
                          {certificate?.status ? "Approved" : "Pending"}
                        </button>
                      </td>
                      <td className="action-td">
                        {/* <Btn
                          color="primary"
                          className="mx-auto mt-3"
                          onClick={() => {
                            setModalOpen(true);
                            setUserId(certificate?._id);
                          }}
                        >
                          ছবি
                        </Btn> */}

                        {((certificate?.status &&
                          userInfo?.userType !== "user") ||
                          !certificate?.status) && (
                          <Link
                            to={"/certificate/edit"}
                            state={{ certificate }}
                          >
                            <i
                              className="fa fa-edit"
                              title="Click to edit"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        )}
                        <Link to={"/certificate/view"} state={{ certificate }}>
                          <i
                            className="fa fa-eye"
                            title="View details"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        {certificate?.status &&
                          userInfo?.userType !== "user" && (
                            <span
                              onClick={() =>
                                deleteCertificate(certificate?._id)
                              }
                            >
                              <i
                                className="fa fa-trash"
                                title="Delete"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        {!certificate?.status && (
                          <span
                            onClick={() => deleteCertificate(certificate?._id)}
                          >
                            <i
                              className="fa fa-trash"
                              title="Delete"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                {isLoading && certificates?.length === 0 && (
                  <tr className="data-not-found">
                    <td colSpan={7}>Data not found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {certificates?.length > 0 && (
            <Pagination
              visibleLinks={10}
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          )}

          {/* modal area */}
          {/* <CommonModal
            centered
            modalBodyClassName="social-profile text-start"
            isOpen={modalOpen}
            toggle={modalTwoToggle}
          >
            <div className="modal-toggle-wrapper">
              <H3>Picture Upload</H3>
              <hr />
              <Form>
                <Row className="g-3">
                  <Col md="12">
                    <Label>&nbsp; Picture</Label>
                    <Input
                      className="form-control"
                      name="file"
                      type="file"
                      placeholder="Enter Your Email"
                      onChange={onFileChange}
                    />
                  </Col>
                  <Col>
                    {imageUrl && (
                      <img
                        src={
                          "http://localhost:5000/uploads/66dc95d022a49dd5f815d01d-20240805_145339.jpg"
                        }
                        alt="Uploaded"
                      />
                    )}
                    <img
                      src={
                        "http://localhost:5000/uploads/66dc95d022a49dd5f815d01d-20240805_145339.jpg"
                      }
                      alt="Uploaded"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="12">
                    {imageUrl}
                    <Btn color="primary" onClick={onFileUpload}>
                      Submit
                    </Btn>
                  </Col>
                </Row>
              </Form>
            </div>
          </CommonModal> */}
        </CardBody>
      </Card>
    </Col>
  );
};

export default HtmlSourcedData;
